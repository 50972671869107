import { Link } from 'gatsby'
import React from 'react'
import style from './footer.module.scss'

const Footer = ({ data }) => {
  return (
    <footer className={style.footer}>
      <div className="u-wrapper">
        <p className={style.p}>
          &copy; nkCreation {new Date().getFullYear()} -{' '}
          <Link to="/mentions-legales/">Mentions légales</Link>
        </p>
      </div>
    </footer>
  )
}

export default Footer
